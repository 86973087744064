<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('customerLoanRequest.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('customerLoanRequest.pageTitle'),
                        href: '/admin/customer-loan-requests'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <ts-panel>
                    <form-wizard
                        :start-index.sync="activeIndex"
                        color="#00acac"
                        :title="''"
                        :subtitle="''"
                        finishButtonText="Finish"
                        errorColor="red"
                        ref="wizard"
                        @on-complete="onComplete"
                    >
                        <wizard-step
                            slot-scope="props"
                            slot="step"
                            :tab="props.tab"
                            :transition="props.transition"
                            :index="props.index"
                        >
                            <div v-if="model.company_id == 1"
                                slot="active-step"
                                style="display: flex; justify-content:center; align-items: center;"
                            >
                                <lottie
                                    v-if="props.index == 4"
                                    :options="defaultOptions"
                                    :height="65"
                                    :width="65"
                                />
                                <lottie
                                    v-else
                                    :options="defaultOptions2"
                                    :height="65"
                                    :width="65"
                                />
                            </div>
                            <div v-else
                                slot="active-step"
                                style="display: flex; justify-content:center; align-items: center;"
                            >
                                <lottie
                                    v-if="props.index == 6"
                                    :options="defaultOptions"
                                    :height="65"
                                    :width="65"
                                />
                                <lottie
                                    v-else
                                    :options="defaultOptions2"
                                    :height="65"
                                    :width="65"
                                />
                            </div>               
                        </wizard-step>
                        <tab-content
                            style="min-height: 460px !important;"
                            :title="
                                $t('customerLoanRequest.customerInformation')
                            "
                            icon="fas fa-users"
                            :before-change="
                                () => validateStep('customerInformation')
                            "
                        >
                            <customer-information
                                v-model="model"
                                :validation="errors"
                                ref="customerInformation"
                                :clear="clear"
                            />
                        </tab-content>
                        <tab-content
                            style="min-height: 460px !important;"
                            :title="$t('customerLoanRequest.loanInformation')"
                            icon="fas fa-donate"
                            :before-change="
                                () => validateStep('loanInformation')
                            "
                        >
                            <loan-information
                                v-model="model"
                                :validation="errors"
                                ref="loanInformation"
                            />
                        </tab-content>
                        <tab-content
                            style="min-height: 460px !important;"
                            :title="
                                $t(
                                    'customerLoanRequest.sourceOfIncomeAndExpenses'
                                )
                            "
                            icon="fas fa-file-invoice-dollar"
                        >
                            <source-of-income-and-expenses
                                v-model="model"
                                :validation="errors"
                                ref="sourceOfIncomeAndExpenses"
                            />
                        </tab-content>
                        <tab-content v-if="model.company_id == 1"
                            style="min-height: 460px !important;"
                            :title="
                                $t(
                                    'customerLoanRequest.debtsOwedAndAbilityToBorrowMoney'
                                )
                            "
                            icon="fas fa-credit-card"
                            :before-change="onSave"
                        >
                            <debts-owed-and-ability-to-borrow-money
                                v-model="model"
                                :validation="errors"
                                ref="debtsOwedAndAbilityToBorrowMoney"
                                :clear="clear"
                            />
                        </tab-content>
                        <tab-content v-else
                            style="min-height: 460px !important;"
                            :title="
                                $t(
                                    'customerLoanRequest.debtsOwedAndAbilityToBorrowMoney'
                                )
                            "
                            icon="fas fa-credit-card"
                        >
                            <debts-owed-and-ability-to-borrow-money
                                v-model="model"
                                :validation="errors"
                                ref="debtsOwedAndAbilityToBorrowMoney"
                            />
                        </tab-content>
                        <tab-content v-if="model.company_id != 1"
                            style="min-height: 460px !important;"
                            :title="
                                $t(
                                    'customerLoanRequest.loanCollateral'
                                )
                            "
                            icon="fas fa-credit-card"
                        >
                            <loan-collateral
                                v-model="model"
                                :validation="errors"
                                ref="loanCollateral"
                            />
                        </tab-content>
                        <tab-content v-if="model.company_id != 1"
                            style="min-height: 460px !important;"
                            :title="
                                $t(
                                    'customerLoanRequest.customerAssets'
                                )
                            "
                            icon="fas fa-sitemap"
                            :before-change="onSave"
                        >
                            <customer-assets
                                v-model="model"
                                :validation="errors"
                                ref="customerAssets"
                                :clear="clear"
                            />
                        </tab-content>
                        <tab-content
                            title="Done"
                            icon=" "
                            style="min-height: 460px !important;"
                        >
                            <lottie
                                v-if="activeIndex == 1"
                                :options="defaultOptions"
                                :height="200"
                                :width="200"
                            />
                            <show />
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-left">
                                <wizard-button
                                    v-if="
                                        props.activeTabIndex == 0 
                                    "
                                    @click.native="$router.push({ name: 'customer-loan-request' })"
                                    :style="'background:#e2e2e2'"
                                    >{{
                                        $t("cancel")
                                    }}</wizard-button
                                >
                            </div>
                            <div class="wizard-footer-left">
                                <wizard-button
                                    v-if="
                                        props.activeTabIndex > 0 &&
                                            !props.isLastStep
                                    "
                                    @click.native="props.prevTab()"
                                    :style="'background:#e2e2e2'"
                                    >{{
                                        $t("customerLoanRequest.previous")
                                    }}</wizard-button
                                >
                            </div>
                            <div class="wizard-footer-right">
                                <wizard-button
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab()"
                                    class="wizard-footer-right"
                                    :style="props.fillButtonStyle"
                                    :disabled="nextStep"
                                >
                                    <i
                                        class="fa fa-spinner spin mr-2"
                                        v-if="nextStep"
                                    ></i>
                                    {{ $t("customerLoanRequest.next") }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    @click.native="onComplete"
                                    class="wizard-footer-right finish-button"
                                    :style="props.fillButtonStyle"
                                    :disabled="nextStep"
                                >
                                    <i
                                        class="fa fa-spinner spin mr-2"
                                        v-if="nextStep"
                                    ></i>
                                    {{
                                        props.isLastStep
                                            ? $t("customerLoanRequest.done")
                                            : $t("customerLoanRequest.next")
                                    }}
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                </ts-panel>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Lottie from "vue-lottie";
import animationData from "@/assets/animation.js";
import animationData2 from "@/assets/animation2.js";
import {
    FormWizard,
    WizardStep,
    TabContent,
    WizardButton
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { Errors } from "form-backend-validation";
import moment from "moment";
import CustomerInformation from "./components/customer-information.vue";
import LoanInformation from "./components/loan-information.vue";
import SourceOfIncomeAndExpenses from "./components/source-of-income-and-expenses.vue";
import DebtsOwedAndAbilityToBorrowMoney from "./components/debts-owed-and-ability-to-borrow-money.vue";
import loanCollateral from "./components/loan-collateral.vue";
import customerAssets from "./components/customer-assets.vue";
import Show from "./components/show.vue";
import { mapActions } from "vuex";

export default {
    name: "loanRequestCreate",
    components: {
        Lottie,
        FormWizard,
        TabContent,
        WizardStep,
        WizardButton,
        CustomerInformation,
        LoanInformation,
        SourceOfIncomeAndExpenses,
        DebtsOwedAndAbilityToBorrowMoney,
        loanCollateral,
        customerAssets,
        Show
    },
    data() {
        return {
            activeIndex: 0,
            defaultOptions: { animationData: animationData, loop: false },
            defaultOptions2: { animationData: animationData2, loop: false },
            animationSpeed: 1,
            errors: new Errors(),
            loading: false,
            nextStep: false,
            continueNextStep: false,
            clear: false,
            model: {
                customer_id: null,
                requested_date: moment().format("DD-MM-YYYY"),
                loan_type_id: null,
                currency_id: null,
                loan_type_cur_id: null,
                group_id: null,
                is_new_cycle: 1,
                cycle_number: 1,
                request_amount: null,
                request_amount_in_word: null,
                request_loan_duration: null,
                request_installment_type_id: null,
                request_interest_rate: null,
                source_of_income_1: null,
                average_imcome_1: null,
                expense_1: null,
                source_of_income_2: null,
                average_imcome_2: null,
                expense_2: null,
                source_of_income_3: null,
                average_imcome_3: null,
                expense_3: null,
                source_of_income_4: null,
                average_imcome_4: null,
                expense_4: null,
                total_monthly_income: null,
                total_monthly_expense: null,
                mfi_owe_due_1: null,
                mfi_owe_due_1_installment: null,
                mfi_owe_due_1_outstanding: null,
                mfi_owe_due_2: null,
                mfi_owe_due_2_installment: null,
                mfi_owe_due_2_outstanding: null,
                mfi_owe_due_3: null,
                mfi_owe_due_3_installment: null,
                mfi_owe_due_3_outstanding: null,
                total_owe_due_to_be_paid: null,
                total_outstanding: null,
                disbursal_amount: null,
                first_installment_amount: null,
                app_amount: null,
                app_loan_duration: null,
                app_installment_type_id: null,
                app_interest_rate: null,
                interval_1_week: null,
                interval_2_week: null,
                interval_1_month: null,
                admin_fee: null,
                cbc_fee: null,
                monthly_collection_fee: null,
                commune_leader_name: null,
                commune_leader_phone: null,
                witness_name: null,
                witness_phone: null,
                other_name: null,
                other_phone: null,
                emp_co_id: null,
                emp_bm_id: null,
                approve_status: null,
                current_app_level: null,
                approval_comment: null,
                attached_request_form: null,
                attached_contact: null,
                purpose_id: null,
                source_of_expense_1: null,
                source_of_expense_2: null,
                source_of_expense_3: null,
                source_of_expense_4: 'ចំណាយក្នុងគ្រួសារ',
                mfi_owe_due_1_duration: null,
                mfi_owe_due_2_duration: null,
                mfi_owe_due_3_duration: null,
                installment_possibility: null,
                name_loan_collateral_1:null,
                name_loan_collateral_2:null,
                name_loan_collateral_3:null,
                name_loan_collateral_4:null,
                price_loan_collateral_1:null,
                price_loan_collateral_2:null,
                price_loan_collateral_3:null,
                price_loan_collateral_4:null,
                card_type_1:null,
                card_type_2:null,
                card_type_3:null,
                card_type_4:null,
                date_issuance_1:null,
                date_issuance_2:null,
                date_issuance_3:null,
                date_issuance_4:null,
                issue_by_1:null,
                issue_by_2:null,
                issue_by_3:null,
                issue_by_4:null,
                loan_collateral_amount:null,
                home_size:null,
                make_of:null,
                village_land_size:null,
                farm_size:null,
                plantation_size:null,
                is_chicken:false,
                amount_chicken:null,
                is_duck:false,
                amount_duck:null,
                is_geese:false,
                amount_geese:null,
                is_cow:false,
                amount_cow:null,
                is_buffalo:false,
                amount_buffalo:null,
                is_pig:false,
                amount_page:null,
                is_horse:false,
                amount_horse:null,
                is_bicycle:false,
                amount_bicycle:null,
                is_motorcycle:false,
                amount_motorcycle:null,
                is_car:false,
                amount_car:null,
                is_bulldozer:false,
                amount_bulldozer:null,
                company_id: null
            }
        };
    },
    created() {
        this.model.company_id = this.$store.state.authUser.user.default_branch.company_id
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("creditOperation/customerLoanRequest", [
            "getLoanType",
            "getCurrency",
            "getPurpose",
            "getRepaymentMethod",
            "find"
        ]),
        validateStep(name) {
            var refToValidate = this.$refs[name];
            return refToValidate.validateForm();
        },
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getCurrency();
            await this.getPurpose();
            await this.getRepaymentMethod();
            await this.$refs.customerInformation.loadCustomer();
            await this.$refs.loanInformation.loadEmployee();
            await this.$refs.loanInformation.loadBmEmployee();
            this.setCurrencyToDefault();
            this.loading = false;
        },
        setCurrencyToDefault() {
            this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
        },
        onComplete() {
            this.$refs.wizard.reset();
            this.clearInput();
            this.$router.push({ name: "customer-loan-request" });
        },
        async onSave() {
            this.nextStep = true;
            this.continueNextStep = false;
            this.errors = new Errors();
            this.waiting = true;
            await this.$store
                .dispatch(
                    "creditOperation/customerLoanRequest/store",
                    this.model
                )
                .then(response => {
                    this.clear = true;
                    this.waiting = false;
                    this.nextStep = true;
                    this.continueNextStep = true;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                    this.$store.commit(
                        "creditOperation/customerLoanRequest/SET_EDIT_DATA",
                        response.resource
                    );
                })
                .catch(error => {
                    this.waiting = false;
                    this.errors = new Errors(error.errors);
                    this.nextStep = false;
                    this.continueNextStep = false;
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                });
            this.nextStep = false;
            return this.continueNextStep;
        },
        clearInput() {
            this.model.customer_id = null;
            this.model.requested_date = moment().format("DD-MM-YYYY");
            this.model.loan_type_id = null;
            this.model.currency_id = null;
            this.model.loan_type_cur_id = null;
            this.model.group_id = null;
            this.model.is_new_cycle = 1;
            this.model.cycle_number = 1;
            this.model.request_amount = null;
            this.model.request_amount_in_word = null;
            this.model.request_installment_type_id = null;
            this.model.request_interest_rate = null;
            this.model.source_of_income_1 = null;
            this.model.average_imcome_1 = null;
            this.model.expense_1 = null;
            this.model.source_of_income_2 = null;
            this.model.average_imcome_2 = null;
            this.model.expense_2 = null;
            this.model.source_of_income_3 = null;
            this.model.average_imcome_3 = null;
            this.model.expense_3 = null;
            this.model.source_of_income_4 = null;
            this.model.average_imcome_4 = null;
            this.model.expense_4 = null;
            this.model.total_monthly_income = null;
            this.model.total_monthly_expense = null;
            this.model.mfi_owe_due_1 = null;
            this.model.mfi_owe_due_1_installment = null;
            this.model.mfi_owe_due_1_outstanding = null;
            this.model.mfi_owe_due_2 = null;
            this.model.mfi_owe_due_2_installment = null;
            this.model.mfi_owe_due_2_outstanding = null;
            this.model.mfi_owe_due_3 = null;
            this.model.mfi_owe_due_3_installment = null;
            this.model.mfi_owe_due_3_outstanding = null;
            this.model.total_owe_due_to_be_paid = null;
            this.model.total_outstanding = null;
            this.model.disbursal_amount = null;
            this.model.first_installment_amount = null;
            this.model.app_amount = null;
            this.model.app_loan_duration = null;
            this.model.app_installment_type_id = null;
            this.model.app_interest_rate = null;
            this.model.interval_1_week = null;
            this.model.interval_2_week = null;
            this.model.interval_1_month = null;
            this.model.admin_fee = null;
            this.model.cbc_fee = null;
            this.model.monthly_collection_fee = null;
            this.model.commune_leader_name = null;
            this.model.commune_leader_phone = null;
            this.model.witness_name = null;
            this.model.witness_phone = null;
            this.model.other_name = null;
            this.model.other_phone = null;
            this.model.emp_co_id = null;
            this.model.emp_bm_id = null;
            this.model.approve_status = null;
            this.model.current_app_level = null;
            this.model.approval_comment = null;
            this.model.attached_request_form = null;
            this.model.attached_contact = null;
            this.model.purpose_id = null;
            this.model.source_of_expense_1 = null;
            this.model.source_of_expense_2 = null;
            this.model.source_of_expense_3 = null;
            this.model.source_of_expense_4 = null;
            this.model.total_monthly_income = 'ចំណាយក្នុងគ្រួសារ';
            this.model.mfi_owe_due_1_duration = null;
            this.model.mfi_owe_due_2_duration = null;
            this.model.mfi_owe_due_3_duration = null;
            this.model.installment_possibility = null;
            this.model.name_loan_collateral_1 = null;
            this.model.name_loan_collateral_2 = null;
            this.model.name_loan_collateral_3 = null;
            this.model.name_loan_collateral_4 = null;
            this.model.price_loan_collateral_1 = null;
            this.model.price_loan_collateral_2 = null;
            this.model.price_loan_collateral_3 = null;
            this.model.price_loan_collateral_4 = null;
            this.model.card_type_1 = null;
            this.model.card_type_2 = null;
            this.model.card_type_3 = null;
            this.model.card_type_4 = null;
            this.model.date_issuance_1 = null;
            this.model.date_issuance_2 = null;
            this.model.date_issuance_3 = null;
            this.model.date_issuance_4 = null;
            this.model.issue_by_1 = null;
            this.model.issue_by_2 = null;
            this.model.issue_by_3 = null;
            this.model.issue_by_4 = null;
            this.model.loan_collateral_amount = null;
            this.model.home_size = null;
            this.model.make_of = null;
            this.model.village_land_size = null;
            this.model.farm_size = null;
            this.model.plantation_size = null;
            this.model.is_chicken = false;
            this.model.amount_chicken = null;
            this.model.is_duck = false;
            this.model.amount_duck = null;
            this.model.is_geese = false;
            this.model.amount_geese = null;
            this.model.is_cow = false;
            this.model.amount_cow = null;
            this.model.is_buffalo = false;
            this.model.amount_buffalo = null;
            this.model.is_pig = false;
            this.model.amount_page = null;
            this.model.is_horse = false;
            this.model.amount_horse = null;
            this.model.is_bicycle = false;
            this.model.amount_bicycle = null;
            this.model.is_motorcycle = false;
            this.model.amount_motorcycle = null;
            this.model.is_car = false;
            this.model.amount_car = null;
            this.model.is_bulldozer = false;
            this.model.amount_bulldozer = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER LOAN REQUEST",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("creditOperation/customerLoanRequest/RESET_STATE");
        this.$store.commit("creditOperation/customerLoanRequest/CLEAR_RESOURCES");
        next();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: #00acac;
}
.wizard-header {
    padding: 5px !important;
    position: relative;
    border-radius: 3px 3px 0 0;
    text-align: center;
}
</style>
